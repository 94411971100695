import {
  Component,
  MixerComponent,
} from '@customTypes/cloudmix/sessions/components'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  Connection,
  addEdge,
} from '@xyflow/react'
import { RootState } from '@lib/store'
import { projectApi } from '@features/projects/api'
import dagre from '@dagrejs/dagre'
import { v4 as uuidv4 } from 'uuid'
import {
  addPadToNodeAsync,
  removePadFromNodeAsync,
} from '@features/projects/slices/workflow/builder/asyncThunks'
import { ComponentNode } from '@customTypes/cloudmix/workflow'
import { ProjectDetails } from '@customTypes/cloudmix/projects'
import { createLinkId } from '@features/projects/lib/utils'
import { workflowSessionApi } from '@features/sessions/api'

type MixerNode = Node<MixerComponent>

const convertLinksToEdges = (links): Edge[] =>
  links.map(({ source, target }) => ({
    id: createLinkId({ source, target }),
    source: source.componentId,
    sourceHandle: source.padName,
    target: target.componentId,
    targetHandle: target.padName,
    animated: true,
    label: '',
    style: { strokeWidth: 10 },
  }))

const convertComponentsToNodes = (components: Component[]): ComponentNode[] =>
  components.map((component) => ({
    id: component.componentId,
    type: component.type,
    data: {
      ...component,
    },
    position: { x: 0, y: 0 },
  })) as ComponentNode[] // TODO: This is a hack to get around the fact that the type is not being inferred correctly

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const arrangeNodeLayout = (
  nodes: ComponentNode[],
  edges: Edge[]
): ComponentNode[] => {
  if (nodes.length < 1) return []
  dagreGraph.setGraph({ rankdir: 'LR' })
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: 300, height: 145 })
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  return nodes.map((node) => {
    const { x, y } = dagreGraph.node(node.id)
    return {
      ...node,
      position: { x, y },
    }
  })
}

const removeNodeFromLayout = (node: ComponentNode): unknown =>
  dagreGraph.removeNode(node.id)

const removeEdgeFromLayout = (edge: Edge): unknown =>
  dagreGraph.removeEdge(edge.source, edge.target)

const keepExistingNodePositions = (
  newNodes: ComponentNode[],
  existingNodes: ComponentNode[]
) =>
  newNodes.map((newNode) => {
    const existingNode = existingNodes.find((n) => n.id === newNode.id)
    if (existingNode) return { ...newNode, position: existingNode.position }
    return newNode
  })

const getOuterNodePosition = (nodes: Node[]) => {
  if (nodes.length === 0) return { x: 100, y: -100 }

  const existingNodesPositions = nodes.map((node) => node.position)
  return existingNodesPositions.reduce(
    (accumulator, { x, y }) => {
      if (x < accumulator.x || (x === accumulator.x && y > accumulator.y))
        return { x, y }

      return accumulator
    },
    { x: 1000, y: 0 }
  )
}

interface SelectedPad {
  componentId: string
  type: 'source' | 'target'
  name: string
}

interface WorkflowBuilderState {
  project: ProjectDetails | null
  workflow: Pick<ProjectDetails, 'workflow'> | null
  nodes: ComponentNode[]
  edges: Edge[]
  hasChanges: boolean
  selectedComponent: string | null
  selectedEdge: Edge | null
  selectedPad: null | SelectedPad
  skipNextSelectedPadReset: boolean
}

const initialState: WorkflowBuilderState = {
  project: null,
  workflow: null,
  nodes: [],
  edges: [],
  hasChanges: false,
  selectedComponent: null,
  selectedEdge: null,
  selectedPad: null,
  skipNextSelectedPadReset: false,
}

const workflowBuilderSlice = createSlice({
  name: 'workflowBuilder',
  initialState,
  reducers: {
    onNodesChange: (
      state,
      action: PayloadAction<NodeChange<ComponentNode>[]>
    ) => {
      state.nodes = applyNodeChanges<ComponentNode>(action.payload, state.nodes)
    },
    onEdgesChange: (state, action: PayloadAction<EdgeChange[]>) => {
      state.edges = [...applyEdgeChanges(action.payload, state.edges)]
    },
    onConnect: (state, action: PayloadAction<Connection>) => {
      const connection = {
        ...action.payload,
        animated: true,
        style: { strokeWidth: 10 },
      }

      state.edges = addEdge(connection, state.edges)
      state.hasChanges = true
    },
    setHasChanges(state, action: PayloadAction<boolean>) {
      state.hasChanges = action.payload
    },
    addNodes(state, action: PayloadAction<Component[]>) {
      const outerNodePosition = getOuterNodePosition(state.nodes)
      const newNodes = convertComponentsToNodes(action.payload)

      newNodes.forEach((node) => {
        state.nodes.push({
          ...node,
          position: { x: outerNodePosition.x, y: outerNodePosition.y + 195 },
        })
      })

      state.hasChanges = true
    },
    addInputToNode(
      state,
      action: PayloadAction<{
        name?: string
        displayName?: string
        componentId: string
        inputSettings?: { [key: string]: unknown }
      }>
    ) {
      // TODO(ajb): Might be able to use pad factory for this instead
      const { name, displayName, componentId, inputSettings } = action.payload

      const currentNode = state.nodes.find(
        (node) => node.id === componentId
      ) as MixerNode

      if (!currentNode) return

      const generatedDisplayName =
        displayName || `Input ${currentNode.data.inputs.length + 1}`
      const generatedName = name || uuidv4()

      state.nodes = state.nodes.map((n: MixerNode) => {
        if (n.id === componentId) {
          const input = {
            name: generatedName,
            displayName: generatedDisplayName,
            ...inputSettings,
          }
          return {
            ...n,
            data: { ...n.data, inputs: [...n.data.inputs, input] },
          }
        }

        return n
      }) as ComponentNode[]

      state.hasChanges = true
    },
    removeInputFromNode(
      state,
      action: PayloadAction<{
        componentId: string
        inputName: string
      }>
    ): void {
      const { componentId, inputName } = action.payload

      const currentNode = state.nodes.find(
        (node) => node.id === componentId
      ) as MixerNode

      if (!currentNode) return

      const inputIndex = currentNode.data.inputs.findIndex(
        (input) => input.name === inputName
      )

      if (inputIndex === -1) return

      // Remove any edges that may be connected to the input
      const connectedEdges = state.edges.filter((edge) => {
        if (
          edge &&
          edge.target &&
          edge.targetHandle &&
          edge.targetHandle.includes
        ) {
          return (
            edge.target === componentId && edge.targetHandle.includes(inputName)
          )
        }
        return false
      })
      if (connectedEdges.length > 0) {
        state.edges = state.edges.filter(
          (edge) =>
            !connectedEdges.some(
              (connectedEdge) => connectedEdge.id === edge.id
            )
        )
      }

      state.nodes = state.nodes.map((n: MixerNode) => {
        if (n.id === componentId) {
          const filteredInputs = n.data.inputs.filter(
            (input) => input.name !== inputName
          )
          return {
            ...n,
            data: { ...n.data, inputs: filteredInputs },
          }
        }

        return n
      })

      state.hasChanges = true
    },
    updateNode(state, action: PayloadAction<Component>) {
      state.nodes = state.nodes.map((node) => {
        if (action.payload.componentId === node.data.componentId)
          // eslint-disable-next-line no-param-reassign
          node.data = {
            ...action.payload,
          }

        return node
      })
      state.hasChanges = true
    },
    updatePad: (
      state,
      action: PayloadAction<{
        componentId: string
        padName: string
        settings: any
        padType: 'source' | 'target'
      }>
    ) => {
      const { componentId, padName, settings, padType } = action.payload

      const actualPadType = padType === 'source' ? 'output' : 'input'
      const node = state.nodes.find((n) => n.id === componentId)
      if (!node) return

      const padContainer =
        node.data[actualPadType] || node.data[`${actualPadType}s`]
      let updatedPadContainer

      if (Array.isArray(padContainer)) {
        const padIndex = padContainer.findIndex((p) => p.name === padName)
        if (padIndex === -1) return

        updatedPadContainer = [...padContainer]
        updatedPadContainer[padIndex] = {
          ...updatedPadContainer[padIndex],
          ...settings,
        }
      } else if (padContainer && padContainer.name === padName) {
        updatedPadContainer = { ...padContainer, settings }
      } else {
        return
      }

      state.nodes = state.nodes.map((n) => {
        if (n.id === componentId) {
          return {
            ...n,
            data: {
              ...n.data,
              [Array.isArray(padContainer)
                ? `${actualPadType}s`
                : actualPadType]: updatedPadContainer,
            },
          }
        }
        return n
      })

      state.hasChanges = true
    },
    deleteNodes(state, action: PayloadAction<string[]>) {
      state.nodes = state.nodes.filter(({ id }) => !action.payload.includes(id))
      state.edges = state.edges.filter(
        ({ source, target }) =>
          !action.payload.includes(source) && !action.payload.includes(target)
      )
      state.hasChanges = true
    },
    deleteEdges(state, action: PayloadAction<string[]>) {
      state.edges = state.edges.filter(({ id }) => !action.payload.includes(id))
      state.hasChanges = true
    },
    onDeleteEdges(state, action: PayloadAction<Edge[]>) {
      action.payload.forEach((edge) => {
        removeEdgeFromLayout(edge)
      })
      state.hasChanges = true
    },
    onDeleteNodes(state, action: PayloadAction<ComponentNode[]>) {
      const nodesToRemove = action.payload
      nodesToRemove.forEach((node) => {
        removeNodeFromLayout(node)
      })

      state.nodes = state.nodes.filter(
        ({ id }) => !nodesToRemove.map((e) => e.id).includes(id)
      )

      const edges = state.edges.filter(
        ({ source, target }) =>
          !action.payload.map((e) => e.id).includes(source) &&
          !action.payload.map((e) => e.id).includes(target)
      )
      state.edges = edges
      state.hasChanges = true
    },
    selectionChange(
      state,
      action: PayloadAction<{
        nodes: ComponentNode[]
        edges: Edge[]
      }>
    ) {
      // Only select 1 node or edge, with the node taking preference.
      const { nodes, edges } = action.payload
      if (nodes?.length === 1 && edges?.length === 0) {
        state.selectedComponent = nodes[0].id
        state.selectedEdge = null
      } else if (edges?.length === 1 && nodes?.length === 0) {
        state.selectedComponent = null
        state.selectedEdge = edges[0] as Edge
      } else {
        state.selectedComponent = null
        state.selectedEdge = null
      }
      // Pads are handled separately
      if (state.skipNextSelectedPadReset) {
        state.skipNextSelectedPadReset = false
      } else {
        state.selectedPad = null
      }
    },
    setSelectedComponent(state, action: PayloadAction<string>) {
      state.selectedComponent = action.payload
      state.selectedEdge = null
      state.selectedPad = null
    },
    setSelectedPad(
      state,
      action: PayloadAction<{
        componentId: string
        type: 'source' | 'target'
        name: string
        skipNextReset?: boolean
      }>
    ) {
      state.selectedPad = action.payload
      state.selectedEdge = null
      state.selectedComponent = null
      state.skipNextSelectedPadReset = true
    },
    arrangeNodes(state) {
      state.nodes = arrangeNodeLayout(state.nodes, state.edges)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removePadFromNodeAsync.fulfilled, (state, action) => {
        state.nodes = action.payload.nodes as ComponentNode[]
        state.edges = action.payload.edges
        state.hasChanges = true
      })
      .addCase(addPadToNodeAsync.fulfilled, (state, action) => {
        state.nodes = action.payload.nodes as ComponentNode[]
        state.hasChanges = true
      })
      .addMatcher(
        projectApi.endpoints.getProject.matchFulfilled,
        (state, action) => {
          state.project = action.payload
          if (action.payload.workflow) {
            const nodes = convertComponentsToNodes(
              action.payload.workflow.components
            ) as ComponentNode[]
            state.nodes = keepExistingNodePositions(nodes, state.nodes)
            const edges = convertLinksToEdges(
              action.payload.workflow.links
            ) as Edge[]
            state.edges = edges
          }
        }
      )
      .addMatcher(
        workflowSessionApi.endpoints.getWorkflowSession.matchFulfilled,
        (state, action) => {
          state.workflow = action.payload
          if (action.payload.workflow) {
            const nodes = convertComponentsToNodes(
              action.payload.workflow.components
            ) as ComponentNode[]
            state.nodes = keepExistingNodePositions(nodes, state.nodes)
            const edges = convertLinksToEdges(
              action.payload.workflow.links
            ) as Edge[]
            state.edges = edges
          }
        }
      )
  },
})

export const selectNodes = (state: RootState) => state.workflowBuilder.nodes
export const selectEdges = (state: RootState) => state.workflowBuilder.edges

export const selectProjectFormat = (state: RootState) =>
  state.workflowBuilder.project?.mediaFormat

export const selectEdgesAndNodes = createSelector(
  (state: RootState) => state.workflowBuilder,
  ({ edges, nodes }) => ({ edges, nodes })
)

export const selectSelectedComponent = createSelector(
  (state: RootState) => state.workflowBuilder,
  ({
    nodes,
    selectedComponent,
  }: {
    nodes: ComponentNode[]
    selectedComponent: string | null
  }) => nodes.find((node) => node.id === selectedComponent)?.data ?? null
)

export const selectSelectedPad = (state: RootState) =>
  state.workflowBuilder.selectedPad

export const selectSelectedEdge = (state: RootState) =>
  state.workflowBuilder.selectedEdge

export const selectNodesConnectedToSelectedEdge = createSelector(
  [selectNodes, selectSelectedEdge],
  (nodes, selectedEdge) => {
    if (!selectedEdge) return []
    const { source, target } = selectedEdge
    const sourceNode = nodes.find((node: ComponentNode) => node.id === source)
    const targetNode = nodes.find((node: ComponentNode) => node.id === target)

    if (!sourceNode || !targetNode) return []
    return [sourceNode, targetNode]
  }
)

export const selectSelectedPadSettings = createSelector(
  [selectNodes, selectSelectedPad],
  (
    nodes,
    selectedPad
  ): {
    componentId: string
    componentType: string
    padType: 'source' | 'target'
    pad
  } | null => {
    if (!selectedPad) return null
    const { componentId, type, name } = selectedPad
    // TODO: node could have inputs or input / outputs or output. It will have one or the other. any is used to bypass four checks.
    const node = nodes.find((n) => n.id === componentId) as any

    if (!node) return null

    let pads
    if (type === 'target') {
      pads = node.data.inputs || node.data.input
    } else {
      pads = node.data.outputs || node.data.output
    }

    const pad = Array.isArray(pads) ? pads.find((p) => p.name === name) : pads

    if (!pad || pad.name !== name) return null

    return {
      componentId: node.id,
      componentType: node.data.type,
      pad,
      padType: type,
    }
  }
)

export const {
  setHasChanges,
  addNodes,
  updateNode,
  updatePad,
  deleteNodes,
  deleteEdges,
  setSelectedPad,
  onConnect,
  onNodesChange,
  onEdgesChange,
  selectionChange,
  onDeleteEdges,
  onDeleteNodes,
  arrangeNodes,
  setSelectedComponent,
  addInputToNode,
  removeInputFromNode,
} = workflowBuilderSlice.actions

export default workflowBuilderSlice.reducer
