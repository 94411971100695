import { z } from 'zod'
import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import { audioVideoPadSchema } from '../../pads'

export const liveInputSourceComponentSchema = abstractComponent.extend({
  type: z
    .literal(COMPONENT_TYPES.liveInputSource)
    .default(COMPONENT_TYPES.liveInputSource),
  liveInputId: z.string(),
  output: audioVideoPadSchema,
})
