import { z } from 'zod'
import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import { audioVideoPadSchema } from '../../pads'

export const webSourceUrlSchema = z.string().url()

export const webSourceComponentSchema = abstractComponent.extend({
  type: z.literal(COMPONENT_TYPES.webSource).default(COMPONENT_TYPES.webSource),
  output: audioVideoPadSchema,
  url: webSourceUrlSchema,
})
