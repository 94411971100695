import { z } from 'zod'

import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import { audioVideoPadSchema } from '@customTypes/cloudmix/sessions/pads'

export const displayComponentSchema = abstractComponent.extend({
  type: z.literal(COMPONENT_TYPES.display).default(COMPONENT_TYPES.display),
  tokenExpiry: z.number().optional(),
  lobbyPassword: z
    .string()
    .nullish()
    .transform((val) => val ?? null),
  inputs: z.array(audioVideoPadSchema),
})
