import { z } from 'zod'

import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import { audioVideoPadSchema } from '../../pads'

export const contributorComponentSchema = abstractComponent.extend({
  type: z
    .literal(COMPONENT_TYPES.contributor)
    .default(COMPONENT_TYPES.contributor),
  lobbyToken: z.string().optional(),
  lobbyPassword: z
    .string()
    .nullish()
    .transform((v) => v ?? null)
    .default(null),
  inputs: z.array(audioVideoPadSchema),
  outputs: z.array(audioVideoPadSchema),
})
