import { Node } from '@xyflow/react'
import { z } from 'zod'
import { workflowLinkSchema } from '@customTypes/cloudmix/sessions/links'
import { engineIdSchema } from '@customTypes/cloudmix/engine'
import { orgIdSchema } from '@features/organisations/types'
import { mediaFormatSchema } from '@customTypes/cloudmix/common/mediaFormat'
import { projectSessionStatusSchema } from '@customTypes/cloudmix/sessions/status'
import { paginationSchema } from '@customTypes/cloudmix/api'
import { Component, componentSchema } from '../sessions/components'

export const workflowSessionIdSchema = z.string().uuid()
export type WorkflowSessionId = z.infer<typeof workflowSessionIdSchema>

export const workflowSchema = z.object({
  components: z.array(componentSchema),
  links: z.array(workflowLinkSchema),
})

export type Workflow = z.infer<typeof workflowSchema>

export type ComponentNode = Node<Component>

export const workflowSessionDetailsSchema = z.object({
  workflowSessionId: workflowSessionIdSchema,
  name: z.string(),
  workflow: workflowSchema,
  mediaFormat: mediaFormatSchema,
  status: projectSessionStatusSchema,
  startedAt: z.number(),
  stoppedAt: z.number().nullable(),
  engineId: engineIdSchema,
  orgId: orgIdSchema,
})
export type WorkflowSessionDetails = z.infer<
  typeof workflowSessionDetailsSchema
>

export const staffSessionsResponseSchema = z
  .object({
    items: z.array(workflowSessionDetailsSchema),
  })
  .and(paginationSchema)
export type StaffSessionsResponse = z.infer<typeof staffSessionsResponseSchema>
