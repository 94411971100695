import { z } from 'zod'
import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import { v4 as uuidv4 } from 'uuid'
import { audioVideoPadSchema } from '../../pads'

export const liveOutputDestinationComponentSchema = abstractComponent.extend({
  type: z
    .literal(COMPONENT_TYPES.liveOutputDestination)
    .default(COMPONENT_TYPES.liveOutputDestination),
  liveOutputId: z.string(),
  input: audioVideoPadSchema.optional().default(() => {
    const id = uuidv4()
    return { name: id, displayName: id }
  }),
  enabled: z.boolean().default(false),
})
