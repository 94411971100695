import { z } from 'zod'

import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import {
  rtmpStreamingProtocolSchema,
  rtmpStreamKeySchema,
  rtmpUrlSchema,
} from '@customTypes/cloudmix/common/streamingProtocol'
import { v4 as uuidv4 } from 'uuid'
import { audioVideoPadSchema } from '../../pads'
// TODO(ajb): Remove once projects are migrated to media library

// export const rtmpDestinationComponentSchema = rtmpStreamingProtocolSchema.merge(
//   abstractComponent.extend({
//     type: z
//       .literal(COMPONENT_TYPES.rtmpDestination)
//       .default(COMPONENT_TYPES.rtmpDestination),
//     input: audioVideoPadSchema.optional(),
//     enabled: z.boolean().default(false),
//   })
// )
export const rtmpDestinationComponentSchema = abstractComponent.extend({
  type: z
    .literal(COMPONENT_TYPES.rtmpDestination)
    .default(COMPONENT_TYPES.rtmpDestination),
  input: audioVideoPadSchema.optional().default(() => {
    const id = uuidv4()
    return { name: id, displayName: id }
  }),
  enabled: z.boolean().default(false),
  address: rtmpUrlSchema.default('rtmp://'),
  streamKey: rtmpStreamKeySchema,
})
