import { z } from 'zod'

export const framerates = [24, 25, 30, 50, 60]
export const fpsSchema = z.coerce
  .number()
  .refine((val) => framerates.includes(val))

export type Framerate = 24 | 25 | 30 | 50 | 60

export const serializeDimensions = (d: { width: number; height: number }) =>
  `${d.width}x${d.height}`

export const deserializeDimensions = (value: string) => {
  const [width, height] = value.split('x').map(Number)
  return { width, height }
}

// DISCUSSION: Dimensions are set via API. dimensions and dimensionsSchema need to become numbers.
export const dimensions = [
  { width: 1920, height: 1080 },
  { width: 1280, height: 720 },
] as const

const dimensionSchema = z
  .object({
    width: z.number(),
    height: z.number(),
  })
  .or(z.string().transform(deserializeDimensions))

export const sampleRates = [48000, 44100, 22050]
export const sampleRateSchema = z.coerce
  .number()
  .refine((val) => sampleRates.includes(val))

export type SampleRate = 48000 | 44100 | 22050

export const mediaFormatSchema = z.object({
  fps: fpsSchema,
  dimensions: dimensionSchema.refine((dim) =>
    dimensions.some(
      (option) => option.width === dim.width && option.height === dim.height
    )
  ),
  sampleRate: sampleRateSchema,
})

export type MediaFormat = z.infer<typeof mediaFormatSchema>
