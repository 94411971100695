import { z } from 'zod'
import {
  paginationRequestSchema,
  paginationSchema,
} from '@customTypes/cloudmix/api'
import {
  workflowSchema,
  workflowSessionIdSchema,
} from '@customTypes/cloudmix/workflow'
import { mediaFormatSchema } from '@customTypes/cloudmix/common/mediaFormat'
import { projectSessionStatusSchema } from '@customTypes/cloudmix/sessions/status'

const sessionsDetailsSchema = z.object({
  workflowSessionId: workflowSessionIdSchema,
  name: z.string(),
  workflow: workflowSchema,
  mediaFormat: mediaFormatSchema,
  status: projectSessionStatusSchema,
  startedAt: z.number().nullable(),
  stoppedAt: z.number().nullable(),
  engineId: z.string().nullable(),
  parentResource: z.string().nullable(),
})
export type SessionsDetails = z.infer<typeof sessionsDetailsSchema>

const listSessionsResponseSchema = z
  .object({
    items: z.array(sessionsDetailsSchema),
  })
  .and(paginationSchema)

export type ListSessionsResponse = z.infer<typeof listSessionsResponseSchema>

export const listSessionsRequestSchema = z
  .object({
    parentResourceId: z.string().optional(),
    status: projectSessionStatusSchema.optional(),
  })
  .and(paginationRequestSchema)

export type ListSessionsRequest = z.infer<typeof listSessionsRequestSchema>
