import { z } from 'zod'

export const PROJECT_SESSION_STATUS = {
  starting: 'starting',
  active: 'active',
  stopping: 'stopping',
  stopped: 'stopped',
} as const
export const projectSessionStatusSchema = z.nativeEnum(PROJECT_SESSION_STATUS)
export type ProjectSessionStatus = keyof typeof PROJECT_SESSION_STATUS
