import { mediaProjectsApi } from '@features/mediaProjectsApi/api'
import {
  WithSessionId,
  WorkflowSessionDetail,
  WorkflowSessionResponse,
} from '@features/sessions/types'
import { Component } from '@customTypes/cloudmix/sessions/components'
import { ProjectLink } from '@customTypes/cloudmix/sessions/links'
import { OrgId } from '@features/organisations/types'
import { WorkflowSessionId } from '@customTypes/cloudmix/workflow'
import {
  PaginationRequest,
  paginationRequestSchema,
} from '@customTypes/cloudmix/api'
import {
  ListSessionsRequest,
  listSessionsRequestSchema,
  ListSessionsResponse,
} from '@customTypes/cloudmix/sessions'
import { ProjectId } from '@customTypes/cloudmix/projects'

const baseUri = `${process.env.NEXT_PUBLIC_MEDIA_LIBRARY_API_URI}`

export const workflowSessionApi = mediaProjectsApi.injectEndpoints({
  endpoints: (build) => ({
    createProjectSessions: build.mutation<
      WorkflowSessionDetail,
      { projectId: ProjectId }
    >({
      query: ({ projectId }) => ({
        url: `${baseUri}/sessions`,
        method: `POST`,
        body: {
          projectId,
        },
      }),
      invalidatesTags: () => [
        { type: 'WorkflowSession', id: 'PARTIAL-LIST' },
        { type: 'Project', id: 'PARTIAL-LIST' },
      ],
    }),
    getWorkflowSession: build.query<
      WorkflowSessionDetail,
      { workflowSessionId: WorkflowSessionId }
    >({
      query: ({ workflowSessionId }) => ({
        url: `${baseUri}/sessions/${workflowSessionId}`,
        method: `GET`,
      }),
      keepUnusedDataFor: 10,
      providesTags: (_result, _error, arg) => [
        { type: 'WorkflowSession', id: arg.workflowSessionId },
      ],
    }),
    listWorkflowSession: build.query<ListSessionsResponse, ListSessionsRequest>(
      {
        query: ({ pageSize, pageIndex, parentResourceId, status }) => {
          const params = listSessionsRequestSchema.parse({
            pageIndex,
            pageSize,
            parentResourceId,
            status,
          })

          return {
            url: `${baseUri}/sessions`,
            method: 'GET',
            params,
          }
        },
        providesTags: (result) =>
          result
            ? [
                ...result.items.map((val) => ({
                  type: 'WorkflowSession' as const,
                  id: val.workflowSessionId,
                })),
                {
                  type: 'WorkflowSession',
                  id: 'PARTIAL-LIST',
                },
              ]
            : [{ type: 'WorkflowSession', id: 'PARTIAL-LIST' }],
      }
    ),
    listAllWorkflowSessions: build.query<
      WorkflowSessionResponse,
      PaginationRequest
    >({
      query: ({ pageIndex, pageSize }) => {
        const queryParams = paginationRequestSchema.parse({
          pageIndex,
          pageSize,
        })

        return {
          url: `${baseUri}/sessions`,
          method: 'GET',
          params: {
            json: JSON.stringify({
              ...queryParams,
              // TODO(jd): Uncomment this when the backend supports it
              // filters: [
              //   { key: 'startedAt', op: 'ge', value: fromDate },
              //   { key: 'stoppedAt', op: 'le', value: toDate },
              // ],
            }),
          },
        }
      },
    }),
    stopWorkflowSession: build.mutation<
      void,
      { workflowSessionId: WorkflowSessionId; orgId?: OrgId }
    >({
      query: ({ workflowSessionId, orgId }) => {
        // This has to be part of the URL for baseQueryWithOrgId(). params helper is added after baseQueryWithOrgId()
        const query = orgId ? `?orgId=${orgId}` : ''
        return {
          url: `${baseUri}/sessions/${workflowSessionId}${query}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: 'WorkflowSession', id: 'PARTIAL-LIST' },
        { type: 'WorkflowSession', id: arg.workflowSessionId },
        { type: 'Project', id: 'PARTIAL-LIST' },
      ],
    }),
    createComponent: build.mutation<
      { componentId: string },
      WithSessionId<{ component: Component }>
    >({
      query: ({ sessionId, component }) => ({
        url: `${baseUri}/sessions/${sessionId}/components`,
        method: `POST`,
        body: {
          component,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'WorkflowSession', id: arg.sessionId },
      ],
    }),
    removeComponent: build.mutation<
      void,
      WithSessionId<{ componentId: string }>
    >({
      query: ({ sessionId, componentId }) => ({
        url: `${baseUri}/sessions/${sessionId}/components/${componentId}`,
        method: `DELETE`,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'WorkflowSession', id: arg.sessionId },
      ],
    }),
    createLink: build.mutation<
      { linkId: string },
      WithSessionId<{ link: ProjectLink }>
    >({
      query: ({ sessionId, link }) => ({
        url: `${baseUri}/sessions/${sessionId}/links`,
        method: `POST`,
        body: {
          link,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'WorkflowSession', id: arg.sessionId },
      ],
    }),
    removeLink: build.mutation<void, WithSessionId<{ linkId: string }>>({
      query: ({ sessionId, linkId }) => ({
        url: `${baseUri}/sessions/${sessionId}/links/${linkId}`,
        method: `DELETE`,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'WorkflowSession', id: arg.sessionId },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetWorkflowSessionQuery,
  useListWorkflowSessionQuery,
  useListAllWorkflowSessionsQuery,
  useCreateProjectSessionsMutation,
  useStopWorkflowSessionMutation,
  useCreateComponentMutation,
  useRemoveComponentMutation,
  useCreateLinkMutation,
  useRemoveLinkMutation,
} = workflowSessionApi
