import { z } from 'zod'
import { paginationSchema } from '../api'

export const engineIdSchema = z.string().uuid()
export type EngineId = z.infer<typeof engineIdSchema>

export const engineSpecificationSchema = z.object({
  cpuCount: z.number(),
  gpuCount: z.number(),
})
export type EngineSpecification = z.infer<typeof engineSpecificationSchema>

export const vendorSchema = z.object({
  vendorId: z.string(),
  vendorRegion: z.string(),
  vendorResourceId: z.string(),
})
export type Vendor = z.infer<typeof vendorSchema>

export const versionSchema = z.object({
  engine: z.string(),
  mediasoup: z.string(),
})
export type Version = z.infer<typeof versionSchema>

export const engineDetailsSchema = z.object({
  engineId: engineIdSchema,
  poolName: z.string(),
  regionName: z.string(),
  baseUrl: z.string().nullable(),
  specifications: engineSpecificationSchema,
  version: versionSchema,
  launchedAt: z.number(),
  lastCheckIn: z.number().optional(),
  status: z.string(),
  vendorDetails: vendorSchema.nullable(),
})
export type EngineDetails = z.infer<typeof engineDetailsSchema>

const listEnginesResponseSchema = z
  .object({
    items: z.array(engineDetailsSchema),
  })
  .and(paginationSchema)
export type ListEnginesResponse = z.infer<typeof listEnginesResponseSchema>
