import { z } from 'zod'
import { componentIdSchema } from '@customTypes/cloudmix/sessions/components/base'

export const padReferenceSchema = z.object({
  componentId: componentIdSchema,
  padName: z.string(),
})

export const projectLinkSchema = z.object({
  linkId: z.string().optional(),
  source: padReferenceSchema,
  target: padReferenceSchema,
  active: z.boolean().optional(),
})

export const workflowLinkSchema = projectLinkSchema.extend({
  state: z.enum(['active', 'inactive']).optional(),
})

export type LinkId = string
export type ProjectLink = z.infer<typeof projectLinkSchema>
export type WorkflowLink = z.infer<typeof workflowLinkSchema>
export type WithLinkId<T = void> = T & { linkId: LinkId }
