import { z } from 'zod'
import { Operations, PermissionType } from '@lib/permissions'

export const PermissionRules = {
  ORG_CREATE: 'org:create',
  ORG_LIST: 'org:list',
  ORG_EDIT: 'org:edit',
  ORG_DELETE: 'org:delete',
  ORG_INVITATION_ISSUE: 'org:invitation:issue',
  ORG_INVITATION_LIST_ALL: 'org:invitation:list:all',
  ORG_INVITATION_REVOKE_ALL: 'org:invitation:revoke:all',
  ORG_APIKEY_CREATE: 'org:apiKey:create',
  ORG_APIKEY_LIST: 'org:apiKey:list',
  ORG_APIKEY_DELETE: 'org:apiKey:delete',
  ORG_SUBSCRIPTION_MANAGE: 'org:subscription:manage',
  ORG_MEMBER_LIST: 'org:member:list',
  ORG_MEMBER_UPDATE: 'org:member:update',
  ORG_MEMBER_UPDATE_ROLE_FROM_OWNER: 'org:member:update:role:from:owner',
  ORG_MEMBER_UPDATE_ROLE_TO_OWNER: 'org:member:update:role:to:owner',
  ORG_MEMBER_DELETE: 'org:member:delete',
  ORG_FEATURE_LIST: 'org:feature:list',
  ORG_FEATURE_ADD: 'org:feature:add',
  ORG_FEATURE_REMOVE: 'org:feature:remove',
  ORG_GROUP_EDIT: 'orgGroup:edit',
  ORG_GROUP_DELETE: 'orgGroup:delete',
  ORG_GROUP_INVITATION_ISSUE: 'orgGroup:invitation:issue',
  STAFF_MEMBER_CREATE: 'staffMember:create',
  STAFF_MEMBER_LIST: 'staffMember:list',
  STAFF_MEMBER_UPDATE: 'staffMember:update',
  STAFF_MEMBER_DELETE: 'staffMember:delete',
  USER_CREATE: 'user:create',
  USER_LIST: 'user:list',
  USER_UPDATE: 'user:update',
  WORKFLOW_BUILDER_READ: 'workflow:builder:read',
  WORKFLOW_BUILDER_WRITE: 'workflow:builder:write',
} as const

export const memberRole = z.enum(['Owner', 'Admin', 'Member'])
export type MemberRoles = z.infer<typeof memberRole>

// single types
export const orgIdSchema = z.string().uuid()
export type OrgId = z.infer<typeof orgIdSchema>

// objects
const invitation = z.object({
  orgId: orgIdSchema,
  orgDisplayName: z.string(),
  expiresAt: z.coerce.date(),
})
export type Invitation = z.infer<typeof invitation>

const resourceLimitsSchema = z.object({
  concurrentEvents: z.number(),
  concurrentHighlightEncodes: z.number(),
  concurrentContentEncodes: z.number(),
})

export const orgMemberSchema = z.object({
  emailAddress: z.string().email(),
  orgRole: memberRole,
  firstName: z.string(),
  lastName: z.string(),
})
export type OrgMember = z.infer<typeof orgMemberSchema>

// complex objects
const receivedInvitation = invitation.extend({
  invitedBy: z.string().email(),
})
export type ReceivedInvitation = z.infer<typeof receivedInvitation>

const issuedInvitation = invitation.extend({
  inviteeEmailAddress: z.string().email(),
})
export type IssuedInvitation = z.infer<typeof issuedInvitation>

// exported types
export type Features = Record<string, boolean>

export interface Org {
  id: OrgId
  displayName: string
  role: MemberRoles
  isActive: boolean
}

const permissionTypeSchema = z.nativeEnum(PermissionType)
const permissionOperationSchema = z.nativeEnum(Operations)

const permissionRuleSchema = z.object({
  type: permissionTypeSchema,
  resourcePattern: z.array(z.string()),
  operations: permissionOperationSchema,
})
export type PermissionRule = z.infer<typeof permissionRuleSchema>

const hasOrgAccessSchema = z.object({
  orgId: orgIdSchema,
  hasOrgAccess: z.literal(true),
  orgAccessDetails: z.object({
    orgId: orgIdSchema,
    permissionRules: z.array(permissionRuleSchema),
    resourceLimits: resourceLimitsSchema,
  }),
})

const hasNoOrgAccessSchema = z.object({
  hasOrgAccess: z.literal(false),
  noOrgAccessReason: z
    .enum(['orgIdRequired', 'orgNotFound', 'orgInactive'])
    .or(z.string())
    .optional(),
})

const orgAccessSchema = z.discriminatedUnion('hasOrgAccess', [
  hasOrgAccessSchema,
  hasNoOrgAccessSchema,
])

const isStaffSchema = z.object({
  isStaff: z.literal(true),
  staffPermissionRules: z.array(permissionRuleSchema),
})

const isNotStaffSchema = z.object({
  isStaff: z.literal(false),
})

const staffOrNotSchema = z.discriminatedUnion('isStaff', [
  isStaffSchema,
  isNotStaffSchema,
])

export const authenticationResponseSchema = z
  .object({
    clientId: z.string(),
    clientSettings: z.object({
      timezone: z.string(),
    }),
  })
  .and(orgAccessSchema)
  .and(staffOrNotSchema)

export type AuthenticationResponse = z.infer<
  typeof authenticationResponseSchema
>
