import { z } from 'zod'

import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import {
  srtPassphraseSchema,
  srtStreamIdSchema,
  srtStreamingProtocolSchema,
  srtUrlSchema,
} from '@customTypes/cloudmix/common/streamingProtocol'
import { v4 as uuidv4 } from 'uuid'
import { audioVideoPadSchema } from '../../pads'

// export const srtDestinationComponentSchema = srtStreamingProtocolSchema.merge(
//   abstractComponent.extend({
//     type: z
//       .literal(COMPONENT_TYPES.srtDestination)
//       .default(COMPONENT_TYPES.srtDestination),
//     input: audioVideoPadSchema.optional(),
//     enabled: z.boolean().default(false),
//   })
// )
// TODO(ajb): Remove once projects are migrated to media library
export const srtDestinationComponentSchema = abstractComponent.extend({
  type: z
    .literal(COMPONENT_TYPES.srtDestination)
    .default(COMPONENT_TYPES.srtDestination),
  input: audioVideoPadSchema.optional().default(() => {
    const id = uuidv4()
    return { name: id, displayName: id }
  }),
  enabled: z.boolean().default(false),
  passphrase: srtPassphraseSchema.default(null),
  streamId: srtStreamIdSchema.default(null),
  latency: z
    .number()
    .min(1)
    .max(10000)
    .default(1000)
    .transform((v) => v ?? 1000),
  location: srtUrlSchema.default('srt://'),
  mode: z.literal('caller').default('caller'),
})
