import { z } from 'zod'
import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import { audioVideoPadSchema } from '../../pads'

export const imageSourceLocationSchema = z.string().url()

export const imageSourceComponentSchema = abstractComponent.extend({
  type: z
    .literal(COMPONENT_TYPES.imageSource)
    .default(COMPONENT_TYPES.imageSource),
  location: imageSourceLocationSchema,
  output: audioVideoPadSchema,
})
