import { z } from 'zod'
import { v4 as uuidv4 } from 'uuid'

export const componentIdSchema = z.string().default(() => uuidv4())

export const COMPONENT_TYPES = {
  mixer: 'mixer',
  testSource: 'testSource',
  webSource: 'webSource',
  rtmpDestination: 'rtmpDestination',
  srtDestination: 'srtDestination',
  liveInputSource: 'liveInputSource',
  imageSource: 'imageSource',
  contributor: 'contributor',
  display: 'display',
  playerSource: 'playerSource',
  liveOutputDestination: 'liveOutputDestination',
} as const

export const componentTypeSchema = z.union([
  z.literal(COMPONENT_TYPES.mixer),
  z.literal(COMPONENT_TYPES.testSource),
  z.literal(COMPONENT_TYPES.webSource),
  z.literal(COMPONENT_TYPES.rtmpDestination),
  z.literal(COMPONENT_TYPES.liveInputSource),
  z.literal(COMPONENT_TYPES.imageSource),
  z.literal(COMPONENT_TYPES.contributor),
  z.literal(COMPONENT_TYPES.display),
  z.literal(COMPONENT_TYPES.playerSource),
  z.literal(COMPONENT_TYPES.liveOutputDestination),
])

export const destinationComponentTypeSchema = z.union([
  z.literal(COMPONENT_TYPES.rtmpDestination),
  z.literal(COMPONENT_TYPES.srtDestination),
  z.literal(COMPONENT_TYPES.display),
  z.literal(COMPONENT_TYPES.liveOutputDestination),
])

export const sourceComponentTypeSchema = z.union([
  z.literal(COMPONENT_TYPES.testSource),
  z.literal(COMPONENT_TYPES.webSource),
  z.literal(COMPONENT_TYPES.liveInputSource),
  z.literal(COMPONENT_TYPES.imageSource),
  z.literal(COMPONENT_TYPES.playerSource),
])

export const sourceAndMixerComponentTypeSchema = z.union([
  sourceComponentTypeSchema,
  z.literal(COMPONENT_TYPES.mixer),
])

export const sourceContributorAndMixerComponentTypeSchema = z.union([
  sourceComponentTypeSchema,
  z.literal(COMPONENT_TYPES.mixer),
  z.literal(COMPONENT_TYPES.contributor),
])

export const abstractComponent = z.object({
  type: z.string(),
  displayName: z.string().optional(),
  componentId: componentIdSchema,
  active: z.boolean().optional(),
  recordingEnabled: z.boolean().optional(),
})
