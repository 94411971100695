import { z } from 'zod'

import {
  abstractComponent,
  COMPONENT_TYPES,
} from '@customTypes/cloudmix/sessions/components/base'
import {
  mixerInputPadSchema,
  mixerOutputPadSchema,
} from '@customTypes/cloudmix/sessions/pads'

export const mixerComponentSchema = abstractComponent.extend({
  type: z.literal(COMPONENT_TYPES.mixer).default(COMPONENT_TYPES.mixer),
  output: mixerOutputPadSchema,
  inputs: mixerInputPadSchema.array(),
})
